import type { DropdownOption } from 'naive-ui';
import { useTypedI18n } from './i18n';
import type { Locales } from './i18n';
import { useCommonStore } from '~/stores/common';

const availableLocales = ref<{ langCode: string; langName: string }[]>([
    { langCode: 'zh-CN', langName: 'Chinese' },
    { langCode: 'en-US', langName: 'English' },
]);

export function useAvailableLocaleList() {
    return {
        availableLocales,
    };
}

export function useGlobalLanguage() {
    const commonStore = useCommonStore();
    const { t, locale } = useTypedI18n();
    const { availableLocales } = useAvailableLocaleList();

    const options = computed<DropdownOption[]>(() =>
        availableLocales.value.map((i) => ({ label: i.langName, key: i.langCode })),
    );

    const nameMapRef = computed(() => {
        const map = new Map<string, string>();
        availableLocales.value.forEach((i) => {
            map.set(i.langCode, i.langName);
        });
        return map;
    });

    const handleSelect = async (key: Locales, option: DropdownOption) => {
        console.log(key);

        locale.value = key;
        commonStore.locale = key;
    };

    return {
        t,
        options,
        handleSelect,
        locale,
        nameMapRef,
    };
}
