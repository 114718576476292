const __pages_import_0__ = () => import("/src/mobile/tickets/index.vue");
const __pages_import_1__ = () => import("/src/mobile/statistics/index.vue");
const __pages_import_2__ = () => import("/src/pages/home/main/index.vue");
const __pages_import_3__ = () => import("/src/pages/home/empty/index.vue");
const __pages_import_4__ = () => import("/src/pages/goods/list/index.vue");
const __pages_import_5__ = () => import("/src/pages/auth/register/index.vue");
const __pages_import_6__ = () => import("/src/pages/auth/login/index.vue");
const __pages_import_7__ = () => import("/src/pages/tickets/index.vue");
const __pages_import_8__ = () => import("/src/pages/statistics/index.vue");
const __pages_import_9__ = () => import("/src/pages/index.vue");
const __pages_import_10__ = () => import("/src/pages/403.vue");
const __pages_import_11__ = () => import("/src/pages/[...all].vue");

const routes = [{"name":"mobile/tickets","path":"/mobile/tickets","component":__pages_import_0__,"props":true,"meta":{"layout":"mobile"}},{"name":"mobile/statistics","path":"/mobile/statistics","component":__pages_import_1__,"props":true,"meta":{"layout":"mobile"}},{"name":"home/main","path":"/home/main","component":__pages_import_2__,"props":true},{"name":"home/empty","path":"/home/empty","component":__pages_import_3__,"props":true},{"name":"goods/list","path":"/goods/list","component":__pages_import_4__,"props":true},{"name":"auth/register","path":"/auth/register","component":__pages_import_5__,"props":true,"meta":{"layout":"auth"}},{"name":"auth/login","path":"/auth/login","component":__pages_import_6__,"props":true,"meta":{"layout":"auth"}},{"name":"tickets","path":"/tickets","component":__pages_import_7__,"props":true},{"name":"statistics","path":"/statistics","component":__pages_import_8__,"props":true},{"name":"index","path":"/","component":__pages_import_9__,"props":true,"meta":{"layout":"home"}},{"name":"403","path":"/403","component":__pages_import_10__,"props":true,"meta":{"layout":"default"}},{"name":"all","path":"/:all(.*)*","component":__pages_import_11__,"props":true,"meta":{"layout":"default"}}];

export default routes;