import Bookmark from '~icons/carbon/bookmark';

// import * as icons from '~/icon';

export function getIcon(name?: string) {
    // if (!name) return Bookmark;
    // return Reflect.get(
    //     icons,
    //     camelcase(name, { pascalCase: true, preserveConsecutiveUppercase: true }),
    // );
    return Bookmark
}
