<template>
    <n-config-provider
        :h="isMobile ? 'full' : ''"
        :locale="localeObj[localeStr].lang"
        :date-locale="localeObj[localeStr].date"
        :theme-overrides="theme"
    >
        <n-dialog-provider>
            <n-message-provider>
                <main :h="isMobile ? 'full' : ''" font-sans text="gray-700 dark:gray-200">
                    <component
                        :is="
                            showThemeEditor && localFlagShowThemeEditor
                                ? NThemeEditor
                                : 'div-container'
                        "
                    >
                        <n-loading-bar-provider>
                            <n-message-provider>
                                <n-dialog-provider>
                                    <router-view />
                                </n-dialog-provider>
                            </n-message-provider>
                        </n-loading-bar-provider>
                    </component>
                </main>
            </n-message-provider>
        </n-dialog-provider>
    </n-config-provider>
</template>

<script setup lang="ts">
import type { Ref } from 'vue';
import {
    NThemeEditor,
    dateDeDE,
    dateEnUS,
    dateFrFR,
    dateZhCN,
    deDE,
    enUS,
    frFR,
    zhCN,
} from 'naive-ui';
import theme from './styles/naive-ui-theme-overrides.json';
import { useThemeditorVisible } from './composables/useThemeEditor';

type Locales = 'zh-CN' | 'en-US' | 'de-DE';
const router = useRouter();
const { locale } = useTypedI18n();
const localeStr = locale as Ref<Locales>;
const localeObj = shallowRef({
    'zh-CN': {
        lang: zhCN,
        date: dateZhCN,
    },
    'en-US': {
        lang: enUS,
        date: dateEnUS,
    },
    'de-DE': {
        lang: deDE,
        date: dateDeDE,
    },
    'fr-FR': {
        lang: frFR,
        date: dateFrFR,
    },
});

const { localFlagShowThemeEditor, showThemeEditor } = useThemeditorVisible();

const isMobile = computed(() => router.currentRoute.value.path.includes('mobile'));
</script>

<style scoped></style>
